/* globals google */

'use strict';

const utilities = require('../utils/utilities');
let storeLocator = require('base/storeLocator/storeLocator');

const isStoreLocator = $('body').find('.store-locator-main').length > 0;
const isPDP = $('body').find('.product-detail').length > 0;
const isPLP = $('body').find('.search-results').length > 0;
const isCheckout = $('body').find('#checkout-main').length > 0;
const isCart = $('body').find('.cart-page').length > 0;
const isStoreLanding = $('body').find('.store-landing').length > 0;
const isModal = isPLP || isCheckout || isCart;
let prevWidth = window.innerWidth;
const miles = 0.000621371;

function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

function getPageLoadDistances() {
    let pageLatitude = $('.store-locator-main').data('currLatitude');
    let pageLongitude = $('.store-locator-main').data('currLongitude');
    let pageLocation = new google.maps.LatLng(pageLatitude, pageLongitude);
    let $storeResults = $('.results .results-list .store-details');

    $storeResults.each(function () {
        let lat = $(this).data('latitude');
        let long = $(this).data('longitude');
        let $storeDistance = $(this).find('.store-distance');

        let storeLocation = new google.maps.LatLng(lat, long);// eslint-disable-next-line
        let distance = google.maps.geometry.spherical.computeDistanceBetween(pageLocation, storeLocation) * miles;
        distance = distance.toFixed(1);

        $storeDistance.text(distance + ' miles');
    });
}

function maps() {
    let map;
    let $mapCanvas = $('.map-canvas');
    // eslint-disable-next-line
    let infowindow = new google.maps.InfoWindow();

    // Init U.S. Map in the center of the viewport
    // eslint-disable-next-line
    let latlng = new google.maps.LatLng(37.09024, -95.712891);
    let mapOptions = {
        scrollwheel: false,
        zoom: 4,
        center: latlng
    };
    // eslint-disable-next-line
    map = new google.maps.Map($mapCanvas[0], mapOptions);
    let mapdiv = $mapCanvas.attr('data-locations');

    mapdiv = JSON.parse(mapdiv);

    // eslint-disable-next-line
    let bounds = new google.maps.LatLngBounds();

    let markerDefault = $mapCanvas.data('iconDefault');
    let markerMyWorkshop = $mapCanvas.data('iconMyWorkshop');

    Object.keys(mapdiv).forEach(function (key) {
        let item = mapdiv[key];
        // let label = parseInt(key, 10) + 1;
        let storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        let marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: item.name,
            icon: {
                url: item.isPreferred || item.isLanding ? markerMyWorkshop : markerDefault,
                anchor: new google.maps.Point(13, 30),
                scaledSize: new google.maps.Size(40, 40)
            }
            // label: { text: label.toString(), color: 'green', fontSize: '16px' }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        if (mapdiv && mapdiv.length === 1) {
            map.setZoom(17);
            map.setCenter(marker.position);
        }
    });

    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }

    getPageLoadDistances();
}

function getPrefferdStoreAddress(storeAddress) {
    let address = storeAddress.address1;
    if (storeAddress.address2.length > 0) {
        address += ' ' + storeAddress.address2;
    }
    if (storeAddress.city.length > 0) {
        address += ' ' + storeAddress.city;
    }
    if (storeAddress.stateCode.length > 0) {
        address += ', ' + storeAddress.stateCode;
    }
    address += ' ' + storeAddress.postalCode;
    return address;
}

function updatePrefferedDirections(direction) {
    let $direction = $('a.store-get-directions');
    let directionHref = $direction.attr('href');
    let updatedDIrectionHref = directionHref.split('=');
    let newDirectionHref = updatedDIrectionHref[0] + '=' + updatedDIrectionHref[1] + '=' + direction.address1 + ',' + direction.city + ',' + direction.stateCode + ',' + direction.postalCode;
    return newDirectionHref;
}

function updateStorePhone(storePhone) {
    let $storePhone = $('a.storelocator-phone');
    let storePhoneHref = $storePhone.attr('href');
    let updatedPhoneHref = storePhoneHref.split(':');
    let newPhoneHref = updatedPhoneHref[0] + '=' + storePhone.phone;
    return newPhoneHref;
}

function toggleCardVisibility(storeID, $cardBodies) {
    $cardBodies.each(function () {
        let $cardBody = $(this);
        let cardStoreID = $cardBody.data('store-id');

        if ((storeID !== undefined) && (cardStoreID !== undefined)) {
            if (cardStoreID === storeID) {
                $cardBody.closest('.card').addClass('preffered d-none');
            } else {
                $cardBody.closest('.card').removeClass('preffered d-none');
            }
        }
    });
}

function makeMyWorkshop($this) {
    let $selectedCard = $this.closest('.card');
    let $selectedCardMakeBtn = $selectedCard.find('.makeItMyWorkshopBtn');
    let $selectedFlag = $selectedCard.find('.preferred-ribbon');
    let $currentWorkshop = $selectedCard.siblings('.my-workshop');
    let $currentWorkshopMakeBtn = $currentWorkshop.find('.makeItMyWorkshopBtn');
    let $currentFlag = $currentWorkshop.find('.preferred-ribbon');

    $selectedCard.addClass('my-workshop');
    $selectedFlag.removeClass('d-none');
    $selectedCardMakeBtn.removeClass('d-inline-flex').addClass('d-none');

    $currentWorkshop.removeClass('my-workshop');
    $currentFlag.addClass('d-none');
    $currentWorkshopMakeBtn.removeClass('d-none').addClass('d-inline-flex');
}

function makeMyWorkshopPDP($this, prefferedStoreData, storeHours) {
    let $selectedCard = $this.closest('.card');
    let $previousCard = $selectedCard.siblings('.my-workshop');
    let $prefferedStore = $('.prefered-store');
    let $bedged = $prefferedStore.find('.preferred-ribbon');
    let $storePhone = $('.store-phone-no');
    let storeDistance = $selectedCard.find('.store-distance').text();
    let $myPrefferedWorkshop = $('.myPrefferedWorkshop .store-details');

    // Stock msg update
    let $stockDiv = $selectedCard.find('.stockMsg').clone(true).html();
    $myPrefferedWorkshop.find('.stockMsg').removeClass('d-none').empty().append($stockDiv);
    $myPrefferedWorkshop.find('.stocksInfo').addClass('d-none');

    $prefferedStore.removeClass('d-none');
    $bedged.removeClass('d-none');
    $storePhone.removeClass('d-none');

    if ($previousCard.length > 0) {
        $previousCard.find('.preferred-ribbon').addClass('d-none');
        $previousCard.find('.makeItMyWorkshopBtn').removeClass('d-none');
        $previousCard.removeClass('my-workshop preffered d-none');
    }

    $selectedCard.addClass('my-workshop preffered d-none');

    // Adding preffered store value
    // Updating Store ID
    let storeId = $this.closest('.card-body').data('store-id');
    $myPrefferedWorkshop.data('store-id', storeId);
    // Updating Store Name
    $myPrefferedWorkshop.find('.store-name .storeName').text(prefferedStoreData.name);
    // updating store Address
    let storeAddress = getPrefferdStoreAddress(prefferedStoreData);
    $myPrefferedWorkshop.find('.store-full-address').text(storeAddress);
    // Updating Store Direction
    let directionHref = updatePrefferedDirections(prefferedStoreData);
    $myPrefferedWorkshop.find('a.store-get-direction').attr('href', directionHref);
    $myPrefferedWorkshop.find('.store-distance').text(storeDistance);
    // Updating Store Phone Number
    let phoneHref = updateStorePhone(prefferedStoreData);
    $myPrefferedWorkshop.find('a.storelocator-phone').attr('href', phoneHref).text(prefferedStoreData.phone);
    // Updating Store Hours
    $myPrefferedWorkshop.find('.store-hours .store-availability-info').removeClass('d-none').html(storeHours);

    if (prefferedStoreData.buildParty) {
        $myPrefferedWorkshop.find('.store-book-party').removeClass('d-none');
    } else {
        $myPrefferedWorkshop.find('.store-book-party').addClass('d-none');
    }
}

function makeMyWorkshopForCheckout($this, prefferedStoreData, storeHours) {
    let $selectCardID = prefferedStoreData.ID;
    let $selectResults = $('.results .card-body[data-store-id=' + $selectCardID + ']');
    let $selectedCard = $selectResults.closest('.card');
    let $prefferedStore = $('.prefered-store');
    let $bedged = $prefferedStore.find('.preferred-ribbon');
    let $storePhone = $('.store-phone-no');
    let storeDistance = $selectedCard.find('.store-distance').text();
    let $myPrefferedWorkshop = $('.myPrefferedWorkshop .store-details');

    $prefferedStore.removeClass('d-none');
    $bedged.removeClass('d-none');
    $storePhone.removeClass('d-none');
    $('.results .card-body').closest('.card.prefered-store').removeClass('previous');
    if ($selectResults.hasClass('checked') || $selectResults.closest('.prefered-store').hasClass('preffered') || $selectResults.closest('.prefered-store').hasClass('previous')) {
        $selectResults.closest('.card.prefered-store').addClass('previous d-none');
        $selectResults.find('.custom-control-input').prop('checked', '');
    }

    let $prefferedStoreResults = $('.results-card .results');
    let $storeSelected = $prefferedStoreResults.find('.card.preffered');
    $storeSelected.removeClass('preffered d-none');

    // Adding preffered store value
    // Updating Store ID
    let storeId = $selectResults.closest('.card-body').data('store-id');
    $myPrefferedWorkshop.attr('data-store-id', storeId);
    // Updating Store Name
    $myPrefferedWorkshop.find('.name-distance .name').text(prefferedStoreData.name);
    $myPrefferedWorkshop.find('.name-distance .store-distance').text(storeDistance);
    // updating store Address
    $myPrefferedWorkshop.find('.address1').text(prefferedStoreData.address1);
    $myPrefferedWorkshop.find('.city').text(prefferedStoreData.city);
    $myPrefferedWorkshop.find('.stateCode .statecode').text(prefferedStoreData.stateCode);
    $myPrefferedWorkshop.find('.stateCode .postalCode').text(prefferedStoreData.postalCode);
    $myPrefferedWorkshop.closest('.card.custom-store-card').find('.custom-control-input').prop('checked', 'checked');
    $myPrefferedWorkshop.closest('.card.custom-store-card').find('.card-body').addClass('checked');

    // Updating Store Phone Number
    let phoneHref = updateStorePhone(prefferedStoreData);
    $myPrefferedWorkshop.find('a.storelocator-phone').attr('href', phoneHref).text(prefferedStoreData.phone);
    // Updating Store Hours
    $myPrefferedWorkshop.find('.store-hours .store-availability-info').removeClass('d-none').html(storeHours);
}

function getCoordinates(storePosition) {
    let isPhoneModal = $('#modal-storeLocator-sm').length > 0;
    let phoneModalPin = $('#modal-storeLocator-sm #store-postal-code').val();
    let pincode = $('#store-postal-code').val();

    if (isPhoneModal && (phoneModalPin !== '')) {
        pincode = $('#modal-storeLocator-sm #store-postal-code').val();
    }
    // eslint-disable-next-line
    let geocoder = new google.maps.Geocoder();

    if (pincode) {
        // User provided a pincode
        geocoder.geocode({ address: pincode }, function (results, status) {
            // eslint-disable-next-line
            if (status === google.maps.GeocoderStatus.OK) {
                let userLatitude = results[0].geometry.location.lat();
                let userLongitude = results[0].geometry.location.lng();
                // eslint-disable-next-line
                let userLocation = new google.maps.LatLng(userLatitude, userLongitude);
                calculateDistances(storePosition, userLocation, miles);
            }
        });
    } else {
        // User clicked on detect location button
        // eslint-disable-next-line
        if (navigator.geolocation) {
            // eslint-disable-next-line
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    let userLatitude = position.coords.latitude;
                    let userLongitude = position.coords.longitude;// eslint-disable-next-line
                    let userLocation = new google.maps.LatLng(userLatitude, userLongitude);
                    calculateDistances(storePosition, userLocation, miles);
                }
            );
        } else {
            // Error
        }
    }
}

function calculateDistances(storePosition, userLocation, miles) {
    for (let i = 0; i < storePosition.length; i++) {
        let $storeID = $('.card').find(`.card-body#store-${storePosition[i].ID}`);// eslint-disable-next-line
        let storeLocation = new google.maps.LatLng(storePosition[i].latitude, storePosition[i].longitude);// eslint-disable-next-line
        let distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(userLocation, storeLocation) * miles;
        storePosition[i] = distanceInMeters.toFixed(1);
        let $storeDistance = $storeID.find('.store-distance');
        $storeDistance.text(storePosition[i] + ' miles');

        let $anchorTag = $storeID.find('.btn-workshop-details');
        if ($anchorTag.is(':visible')) {
            let currentHref = $anchorTag.attr('href');
            if (currentHref.includes('dist=undefined')) {
                currentHref = currentHref.replace('dist=undefined', `dist=${storePosition[i]}`);
            } else if (!currentHref.includes('dist=')) {
                currentHref += `&dist=${storePosition[i]}`;
            }
            $anchorTag.attr('href', currentHref);
        }
    }
}

function pickup() {
    const $pickupinfoSection = $('.pickupinfo-section');
    const $shippingAddressBlock = $('.shipping-address-block');
    const $formGroupNone = $shippingAddressBlock.find('.form-group');

    if ($("#PickUp").is(":checked")) {
        $pickupinfoSection.removeClass('d-none');
        $formGroupNone.addClass('d-none');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_firstName').addClass('d-block');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_lastName').addClass('d-block');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_phone').addClass('d-block');
        $('.shipping-form ').attr('data-address-mode', 'new');
        $('.shipping-form ').find('.shipment-selector-block').addClass('d-none');
    } else {
        $pickupinfoSection.addClass('d-none');
        $formGroupNone.removeClass('d-none');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_firstName').removeClass('d-block');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_lastName').removeClass('d-block');
        $shippingAddressBlock.find('.form-group.dwfrm_shipping_shippingAddress_addressFields_phone').removeClass('d-block');
        $('.shipping-form ').find('.shipment-selector-block').removeClass('d-none');
    }
}

$('input[name="pickupstore"]').on('click', function () {
    pickup();
    let inStoreOOS = $('.inStoreOOS').length > 0;
    let url = $(this).data('url');
    let isStore = {
        isStore: $(this).data('istore'),
        isStoreOOS: inStoreOOS
    };
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: isStore,
        success: function (data) {
            if (data.order) {
                $('body').trigger('checkout:updateCheckoutView',
                    {
                        order: data.order,
                        customer: data.customer,
                        options: { keepOpen: true },
                        shippingDiscounts: data.shippingDiscounts
                    });
            }
            let $saveAddress = $('input[name=dwfrm_shipping_shippingAddress_shippingSaveForFutureUse]');
            if (data.order.shipmentType === 'isStore') {
                if ($saveAddress.is(':checked')) {
                    $saveAddress.attr('checked', false);
                    $saveAddress.val(false);
                }
                $saveAddress.closest('.form-group').addClass('d-none');
                $('input[name=dwfrm_shipping_shippingAddress_shippingAddressUseAsBillingAddress]').attr('checked', false);
                $('input[name=dwfrm_shipping_shippingAddress_shippingAddressUseAsBillingAddress]').closest('.form-group').addClass('d-none');
            } else {
                $saveAddress.closest('.form-group').removeClass('d-none');
                if ($saveAddress.is(':checked')) {
                    $saveAddress.attr('checked', true);
                    $saveAddress.val(true);
                }
                $('input[name=dwfrm_shipping_shippingAddress_shippingAddressUseAsBillingAddress]').closest('.form-group').removeClass('d-none');
                $('input[name=dwfrm_shipping_shippingAddress_shippingAddressUseAsBillingAddress]').attr('checked', true);
            }
            const $preferredStoreDetails = $('.preferredStoreDetails');
            const $shippingAddressBlock = $('.shipping-form');
            if (data.error) {
                $('.submit-shipping').attr('disabled', true);
            } else {
                $('.submit-shipping').attr('disabled', false);
            }
            if ($preferredStoreDetails.length > 0 && $("#PickUp").is(":checked")) {
                const address1 = $preferredStoreDetails.find('address .address1').text();
                const city = $preferredStoreDetails.find('address .city').text();
                const countryCode = $preferredStoreDetails.find('address .countryCode').text();
                const statecode = $preferredStoreDetails.find('address .statecode').text();
                const postalCode = $preferredStoreDetails.find('address .postalCode').text();

                $shippingAddressBlock.find('.form-group .shippingAddressOne').val(address1);
                $shippingAddressBlock.find('.form-group .shippingCountry').val(countryCode);
                $shippingAddressBlock.find('.form-group .shippingState').val(statecode);
                $shippingAddressBlock.find('.form-group .State' + countryCode).val(statecode);
                $shippingAddressBlock.find('.form-group .shippingAddressCity').val(city);
                $shippingAddressBlock.find('.form-group .shippingZipCode').val(postalCode);
            } else {
                if (data.customer.addresses.length > 0) {
                    $('.shipping-form ').attr('data-address-mode', 'edit');
                } else {
                    $('.shipping-form ').attr('data-address-mode', 'new');
                }
                $('.shipping-form .form-group .form-control:not(.shippingCountry)').each(function () {
                    $(this).val('');
                });
                $('.shipping-form .form-group .form-control.shippingCountry').val($(".shipping-form .form-group .form-control.shippingCountry option:first").val());
                if (data.customer.registeredUser && data.customer.addresses.length > 0) {
                    const $shippingAddressforRegisterUser = $('.shipping-form .shipping-address-block');
                    const $SelectedAddressData = $('#shipmentSelector-default').find('.radio-line.checked .custom-control-input');
                    $shippingAddressforRegisterUser.find('.form-group .shippingFirstName').val($SelectedAddressData.data('first-name'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingLastName').val($SelectedAddressData.data('last-name'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingPhoneNumber').val($SelectedAddressData.data('phone'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingAddressOne').val($SelectedAddressData.data('address1'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingAddressTwo').val($SelectedAddressData.data('address2'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingCountry').val($SelectedAddressData.data('country-code'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingState').val($SelectedAddressData.data('state-code'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingAddressCity').val($SelectedAddressData.data('city'));
                    $shippingAddressforRegisterUser.find('.form-group .shippingZipCode').val($SelectedAddressData.data('postal-code'));
                    let $countrySelection = $('#dwfrm_shipping .countrySelection');
                    $countrySelection.find('.countryHide').addClass('d-none');
                    const $countryHide = $countrySelection.find(`.State${$SelectedAddressData.data('country-code')}`).closest('.countryHide');
                    if ($countryHide.length) {
                        $countryHide.removeClass('d-none');
                        $countrySelection.find(`.State${$SelectedAddressData.data('country-code')}`).val($SelectedAddressData.data('state-code'));
                        $countrySelection.find('.country-col:not(.account-country)').addClass('col-sm-6').removeClass('col-12');
                    } else {
                        $countrySelection.find('.country-col').addClass('col-12').removeClass('col-sm-6 col-md-6');
                    }
                }
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
});

$('.modal-storeLocator input[name="radio-test"]').on('click', function () {
    $('.modal-storeLocator .card-body').removeClass('checked');
    if ($('.modal-storeLocator .card-body').hasClass('checked')) {
        $('.modal-storeLocator .card-body').removeClass('checked');
    } else {
        $(this).closest('.card-body').addClass('checked');
    }
});

function findWorkshop() {
    $('.store-search').on('click', '.btn-storelocator-search', function (event) {
        event.preventDefault();
        const $inputField = $('.store-search #store-postal-code');
        const $postalCode = $inputField.val();
        const $selectPickupStore = $('#select-pickup-store');
        if ($postalCode) {
            $('.modal-storeLocator').find('#store-postal-code').val($postalCode);
            $selectPickupStore.find('.btn-storelocator-search').trigger('click');
            $selectPickupStore.modal('show');
        } else {
            $inputField.addClass('is-invalid');
            $('.store-search .invalid-feedback').text($inputField.attr('data-error-message'));
        }
    });
}

function selectPickupStore() {
    $('#select-pickup-store .results-card .results').on('click', '[name="storelocators"]', function () {
        $(this).closest('#select-pickup-store ').find('[name="storelocators"]').removeAttr('checked', '');
        $(this).closest('#select-pickup-store').find('.card-body').removeClass('checked');
        if ($(this).is(':checked')) {
            $(this).attr('checked', 'checked');
            $(this).closest('.card-body').addClass('checked');
        } else {
            $(this).closest('#select-pickup-store').find('[name="storelocators"]').removeAttr('checked', '');
            $(this).closest('#select-pickup-store').find('.card-body').removeClass('checked');
        }
    });
}

function updateStoresResults(data) {
    let $resultsDiv = $('.results');
    let $mapDiv = $('.map-canvas');
    let hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator-no-results').show().removeClass('d-none');
        $('.result-set').hide();
    } else {
        $('.store-locator-no-results').hide().addClass('d-none');
        $('.result-set').show();
    }

    if (isModal && hasResults) {
        const $modalButtons = $('.modal-dialog-storelocator .modal-buttons');
        $modalButtons.removeClass('d-none');
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }
    getCoordinates(data.stores);
    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        let storeID = $('.custom-store-card .myPrefferedWorkshop .store-details').data('store-id');
        let $cardBodies = $('.results-card .card-body');

        toggleCardVisibility(storeID, $cardBodies);
    }

    // Show Store Locator Banner on any search
    $('.store-locator-slot').show();
    highlightDay();
}

function search(element) {
    let dialog = element.closest('.in-store-inventory-dialog');
    let spinner = dialog.length ? dialog.spinner() : $.spinner();
    let $form = element.closest('.store-locator');
    let radius = $('.results').data('radius');
    let url = $form.attr('action');
    let postalCode = $form.find('[name="postalCode"]').val();
    // eslint-disable-next-line
    let geocoder = new google.maps.Geocoder();

    let urlParams = {
        radius: radius,
        isPDP: isPDP,
        isPLP: isPLP,
        isCheckout: isCheckout,
        isCart: isCart
    };

    spinner.start();
    if (isPDP) {
        var builderconfig = $('[data-builderconfig]').data('builderconfig');
        let allPids = [];
        Object.keys(builderconfig).forEach(function (department) {
            allPids = allPids.concat(builderconfig[department]);
        });
        if ($('body').find('.product-bundle-detail').length > 0) {
            allPids.splice(0, 1);
        }
        urlParams.allPids = JSON.stringify(allPids);
    }
    let payload = $form.is('form') ? $form.serialize() : { postalCode: postalCode };

    url = appendToUrl(url, urlParams);
    geocoder.geocode({ address: postalCode }, function (results, status) {
        // eslint-disable-next-line
        if (status === google.maps.GeocoderStatus.OK) {
            let lat = results[0].geometry.location.lat();
            let long = results[0].geometry.location.lng();
            url = appendToUrl(url, { lat: lat, long: long });
            $.ajax({
                url: url,
                type: $form.attr('method'),
                data: payload,
                dataType: 'json',
                success: function (data) {
                    updateStoresResults(data);
                    let $resultInfo = $('.results-count');
                    $resultInfo.find('.search-term').html(postalCode);
                    $resultInfo.find('.search-result-terms').show();
                    $('.select-store').prop('disabled', true);
                    spinner.stop();
                }
            });
        } else {
            $.ajax({
                url: url,
                type: $form.attr('method'),
                data: payload,
                dataType: 'json',
                success: function (data) {
                    updateStoresResults(data);
                    $('.select-store').prop('disabled', true);
                    spinner.stop();
                }
            });
        }
    });
    return false;
}

function updatePDPStockMessage(pdpStockMessage, storeNAme) {
    var pdpStockMessageObj = JSON.parse(pdpStockMessage);
    if (!pdpStockMessageObj && pdpStockMessageObj.length == 0) {
        return;
    }
    $('.preffered-store-name').text(`at ${storeNAme}`);
    Object.keys(pdpStockMessageObj).forEach(function (pid) {
        $(`.${pid}stockmessage`).text(pdpStockMessageObj[pid]);
    });
}

function updatePDPStockHTML(stockMsgResponse) {
    var stockMsgResponseObj = JSON.parse(stockMsgResponse);
    if ($('.preffered-store-name').length === 0) {
        Object.keys(stockMsgResponseObj).forEach(function (pid) {
            $(`.replace-${pid}`).replaceWith(stockMsgResponseObj[pid]);
        });
    }
}

function moveSearchFields() {
    let $pageHeading = $('.page-heading');
    let $pageHeadingWrap = $('.page-heading-wrap');
    let $searchFields = $('.sl-search-fields');
    let $searchFieldsWrap = $('.sl-search-fields-wrap');
    let $map = $('.sl-map');

    if (window.matchMedia('(min-width: 48em)').matches) {
        $pageHeading.prependTo($pageHeadingWrap);
        $searchFields.removeClass('col-12').appendTo($searchFieldsWrap);
    } else {
        $pageHeading.insertBefore($map);
        $searchFields.addClass('col-12').insertBefore($map);
    }
}

function moveStoreDetails() {
    let $storeDetailsContent = $('.store-landing-details');
    let $storeDetailsDesktop = $('.store-details-desktop');
    let $storeDetailsMobile = $('.store-details-mobile');

    if (window.matchMedia('(min-width: 48em)').matches) {
        $storeDetailsContent.appendTo($storeDetailsDesktop);
    } else {
        $storeDetailsContent.appendTo($storeDetailsMobile);
    }
}

function highlightDay() {
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentDay = new Date().getDay();
    const currentDayName = days[currentDay];
    let $storeCards = $('.store-details');
    let $storeHours = $storeCards.find('.store-availability-info');

    $storeHours.find('li:contains(' + currentDayName + ')').each(function () {
        $(this).addClass('current-day');
    });
}

/**
 * Checks for Geolocation Error
 * @param {Object} error - string to check
 */
function showError(error) {
    var $detectLocationButton = $('.detect-location');
    switch (error.code) {
    case error.PERMISSION_DENIED:
        $.spinner().stop();
        console.warn('User denied the request for Geolocation.');
        $detectLocationButton.prop('disabled', true);
        $detectLocationButton.find('.detect-location-label').text($detectLocationButton.find('.detect-location-label').data('disabledmsg'));
        break;
    case error.POSITION_UNAVAILABLE:
        $.spinner().stop();
        console.warn('Location information is unavailable.');
        break;
    case error.TIMEOUT:
        $.spinner().stop();
        console.warn('The request to get user location timed out.');
        break;
    default:
    case error.UNKNOWN_ERROR:
        $.spinner().stop();
        console.warn('An unknown geolocation error occurred.');
        break;
    }

    $('#store-postal-code').prop('disabled', false);
}

$(document).ready(function () {
    findWorkshop();

    if (isCheckout) {
        pickup();
    }

    if (isModal) {
        selectPickupStore();
    }

    $('body').on('click', '.makeItMyWorkshopBtn', function (event) {
        event.preventDefault();
        const $this = $(this);
        let url = $this.data('url');
        let storeDistance = $this.parents('.store-details').find('.store-distance').text().replace(' miles', '');
        let isPDP = $('body').find('.product-detail').length > 0;
        let allPids = [];
        if (isPDP) {
            var builderconfig = $('[data-builderconfig]').data('builderconfig');
            Object.keys(builderconfig).forEach(function (department) {
                allPids = allPids.concat(builderconfig[department]);
            });
            if ($('body').find('.product-bundle-detail').length > 0) {
                allPids.splice(0, 1);
            }
        }

        let storeId = {
            store: $this.data('store'),
            storeDistance: $this.parents('.store-details').find('.store-distance').text(),
            isPDP: isPDP,
            allPids: JSON.stringify(allPids)
        };
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: storeId,
            success: function (data) {
                if (!data.error) {
                    const storeURL = $('.preferred-store-url').val() + '?StoreID=' + data.storeID + '&dist=' + storeDistance;
                    $('.store-detail').html(`<a href="${storeURL}">${data.storeName}</a>, ${data.storeCity}, ${data.storeStateCode}`);
                }
                if (data.pdpStockMessage && data.pdpStockMessage.length) {
                    updatePDPStockMessage(data.pdpStockMessage, data.storeName);
                }
                if (data.stockMsgResponse && data.stockMsgResponse.length) {
                    updatePDPStockHTML(data.stockMsgResponse);
                }

                if (isPDP) {
                    makeMyWorkshopPDP($this, data.storeData, data.storeHours);
                } else {
                    makeMyWorkshop($this);
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('.modal-buttons .select-workshop-button').off('click').on('click', function (event) {
        event.preventDefault();
        const $this = $(this);
        let url = $this.data('url');
        const $selectPickupStore = $('#select-pickup-store');
        let storeDistance = $selectPickupStore.find('[name="storelocators"]:checked').siblings('.custom-control-label').children('.store-details').find('.store-distance').text();
        let storeId = {
            store: $selectPickupStore.find('[name="storelocators"]:checked').siblings('.custom-control-label').children('.store-details').attr('data-store-id'),
            storeDistance: storeDistance
        };
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: storeId,
            success: function (data) {
                const storeURL = $('.preferred-store-url').val() + '?StoreID=' + data.storeID + '&dist=' + storeDistance.replace(' miles', '');
                $selectPickupStore.modal('hide');
                const isPLP = $('body').find('.search-results').length > 0;
                const isChecked = $('.store-switch .storeToggle').is(":checked");
                if (isPLP && data.storeID && isChecked) {
                    window.location.href = utilities.updateURLParams(window.location.href, 'storeid', data.storeID);
                }

                if (!data.error) {
                    const $shippingAddressBlock = $('.shipping-form');
                    $shippingAddressBlock.find('.form-group .shippingAddressOne').val(data.storeData.address1);
                    $shippingAddressBlock.find('.form-group .shippingCountry').val(data.storeData.countryCode);
                    $shippingAddressBlock.find('.form-group .shippingState').val(data.storeData.stateCode);
                    $shippingAddressBlock.find('.form-group .State' + data.storeData.countryCode).val(data.storeData.stateCode);
                    $shippingAddressBlock.find('.form-group .shippingAddressCity').val(data.storeData.city);
                    $shippingAddressBlock.find('.form-group .shippingZipCode').val(data.storeData.postalCode);
                    $shippingAddressBlock.find('.storeId').val(data.storeData.ID);
                    $('.pickupinfo-section .preferredStoreDetails').html(data.renderedTemplate);
                    $('.prefered-store button').attr('data-target', '#select-pickup-store');
                    $('.card.store-search').addClass('d-none');
                    if (data.order) {
                        $('body').trigger('checkout:updateCheckoutView',
                            {
                                order: data.order,
                                customer: data.customer,
                                options: { keepOpen: true }
                            });
                    }
                    if (data.isStoreOOS) {
                        $('.submit-shipping').attr('disabled', true);
                    } else {
                        $('.submit-shipping').attr('disabled', false);
                    }
                    if ($('body').find('.cart-page').length > 0) {
                        const storeObjlist = JSON.parse(data.inventoryMsgObj);
                        Object.keys(storeObjlist).forEach(key => {
                            let $uniqueIdsKey = $(`.cart-lineitem[data-uuid=${key}]`);
                            let $availabilityMsg = $uniqueIdsKey.find('.availability-msg');
                            $uniqueIdsKey.find('.pdp-ship-option').removeClass('check-store-available').addClass('availableStore');
                            $uniqueIdsKey.find('.store-locator-cart span.store-link-name').text('Change Store');
                            if (storeObjlist[key].availabilityMessage === 'Out of Stock') {
                                $availabilityMsg.html(`
                                <span class="bold font-family-sans-serif d-block">Not Available for workshop pickup</span>
                                <span class="storename">${data.storeName}</span>`);
                            } else {
                                $availabilityMsg.html(`
                                    <span class="bold font-family-sans-serif d-block">${storeObjlist[key].availabilityMessage}</span>
                                    <span class="storename">${data.storeName}</span>`);
                            }
                        });
                        $('.store-detail').html(`<a href="${storeURL}">${data.storeName}</a>, ${data.storeCity}, ${data.storeStateCode}`);
                    }
                    if (isPLP) {
                        let $selectStoreLink = $('.select-store-link');

                        $selectStoreLink.each(function () {
                            let $selectStore = $(this).find('.select-store');
                            let $storeName = $(this).find('.store-name');

                            if ($selectStore.length) {
                                $selectStore.hide();
                                $storeName.text(data.storeName).attr('data-storeid', data.storeID).removeClass('d-none');
                            } else {
                                $storeName.text(data.storeName).attr('data-storeid', data.storeID);
                            }
                        });
                        $('.store-detail').html(`<a href="${storeURL}">${data.storeName}</a>, ${data.storeCity}, ${data.storeStateCode}`);
                    }
                }
                makeMyWorkshopForCheckout($this, data.storeData, data.storeHours);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
});

$(window).smartresize(function () {
    let width = window.innerWidth;
    let horzResize = false;
    if (width !== prevWidth) {
        horzResize = true;
        prevWidth = width;
    }

    if (isStoreLocator && horzResize) {
        moveSearchFields();
    }

    if (isStoreLanding && horzResize) {
        moveStoreDetails();
    }
});


storeLocator.init = function () {
    if ($('.map-canvas').data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (!$('.results').data('has-results')) {
        $('.store-locator-no-results').show();
    }

    if (isStoreLocator) {
        moveSearchFields();

        let $mapToggle = $('.btn-map-toggle');
        let $mapBlock = $('.sl-map');

        $mapToggle.on('click', function () {
            let $tglBtn = $(this);
            $tglBtn.siblings('.btn').removeClass('active');
            $tglBtn.addClass('active');

            if ($tglBtn.is('.btn-map-view')) {
                $mapBlock.addClass('js-show');
            } else {
                $mapBlock.removeClass('js-show');
            }
        });
    }

    if (isStoreLanding) {
        moveStoreDetails();
    }

    highlightDay();
};

storeLocator.search = function () {
    $('.store-locator-container, .sl-search-fields').off('submit').on('submit', 'form.store-locator', function (event) {
        event.preventDefault();
        search($(this));
    });
    $('.store-locator-container, .sl-search-fields').off('click').on('click', '.btn-storelocator-search[type="button"]', function (event) {
        event.preventDefault();
        search($(this));
    });
};

storeLocator.detectLocation = function () {
    // clicking on detect location.
    $('.detect-location').off('click').on('click', function (event) {
        event.preventDefault();
        $.spinner().start();
        // eslint-disable-next-line
        if (!navigator.geolocation) {
            $.spinner().stop();
            return;
        }

        $('#store-postal-code').prop('disabled', true);

        // eslint-disable-next-line
        navigator.geolocation.getCurrentPosition(function (position) {
            var $detectLocationButton = $('.detect-location');
            var url = $detectLocationButton.data('action');
            var radius = $('.results').data('radius');
            let isSLPage = $('body').find('.store-locator-main').length > 0;
            let isPDP = $('body').find('.product-detail').length > 0;
            let isPLP = $('body').find('.search-results').length > 0;
            let isCheckout = $('body').find('#checkout-main').length > 0;
            let isCart = $('body').find('.cart-page').length > 0;

            if (isSLPage) {
                radius = $('.store-form .radius').val();
            }

            var urlParams = {
                radius: radius,
                lat: position.coords.latitude,
                long: position.coords.longitude,
                isSLPage: isSLPage,
                isPDP: isPDP,
                isPLP: isPLP,
                isCheckout: isCheckout,
                isCart: isCart
            };

            if (isPDP) {
                var builderconfig = $('[data-builderconfig]').data('builderconfig');
                let allPids = [];
                Object.keys(builderconfig).forEach(function (department) {
                    allPids = allPids.concat(builderconfig[department]);
                });
                if ($('body').find('.product-bundle-detail').length > 0) {
                    allPids.splice(0, 1);
                }
                urlParams.allPids = JSON.stringify(allPids);
            }

            url = appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    updateStoresResults(data);
                    $('.select-store').prop('disabled', true);
                    $('#store-postal-code').prop('disabled', false);
                    $.spinner().stop();
                },
                error: function () {
                    $('#store-postal-code').prop('disabled', false);
                    $.spinner().stop();
                }
            });
        }, showError);
    });
};

storeLocator.changeRadius = function () {
    $('.store-locator-container .refine-radius').change(function () {
        var radius = $(this).val();
        var searchKeys = $('.results').data('search-key');
        var url = $(this).data('action-url');
        var urlParams = {};

        if (searchKeys.postalCode) {
            urlParams = {
                radius: radius,
                postalCode: searchKeys.postalCode
            };
        } else if (searchKeys.lat && searchKeys.long) {
            urlParams = {
                radius: radius,
                lat: searchKeys.lat,
                long: searchKeys.long
            };
        }

        url = appendToUrl(url, urlParams);
        var dialog = $(this).closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                spinner.stop();
                updateStoresResults(data);
                $('.select-store').prop('disabled', true);
            }
        });
    });
};

module.exports = storeLocator;
