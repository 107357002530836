'use strict';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
const appendParamsToUrl = function (url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
};

const showAlertMsg = function ($toastBox, duration) {
    $toastBox.removeClass('d-none').removeAttr('style');
    setTimeout(function () {
        $toastBox.addClass('d-none');
    }, duration);
};

/**
 *
 * @param {string} url - Orignial URL
 * @param {string} paramName - Name of the Parameter
 * @param {null} paramValue - Parameters will be delete
 * @param {undefined} paramValue - Parameters will be created
 * @param {any} paramValue - paramName value will be updated
 * @return {string} - Updated URL
 *
 */

const updateURLParams = function (url, paramName, paramValue) {
    let urlObj = new URL(url); // eslint-disable-line no-undef
    let urlParamObj = new URLSearchParams(urlObj.search); // eslint-disable-line no-undef
    let updatedURL = url;

    if (paramValue === null) {
        urlParamObj.delete(paramName);
    } else if (paramValue === undefined) {
        urlParamObj.set(paramName, '');
    } else if (paramValue) {
        urlParamObj.set(paramName, paramValue);
    }
    updatedURL = `${urlObj.origin}${urlObj.pathname}${(urlParamObj.size ? `?${urlParamObj.toString()}` : '')}`;

    return updatedURL;
};

module.exports = {
    appendParamsToUrl: appendParamsToUrl,
    showAlertMsg: showAlertMsg,
    updateURLParams: updateURLParams
};
